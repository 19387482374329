import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { css } from "@emotion/core";

import {
  FullPageOrangeSection,
  FullPageWhiteSection,
  TitleText,
  BlurbWrapper,
  BlurbText,
  BlurbLink,
} from "../components/styled-components";
import Hero from "../components/hero";
import HexTransition from "../components/hex-transition";
import PartnershipHeaderImages from "../components/partnership-header-images";
import MainLayout from "../components/main-layout";

const CustomSolutions = ({ data }) => {
  return (
    <MainLayout>
      <Helmet>
        <title>BOW - Custom Solutions</title>
        <meta
          name="description"
          content={
            "Whether your target market is C&I, DG or Utility Scale BOW’s services and expertise cover the entire scope of the solar industry. Need a construction manager in Texas? A project manager Colorado? A battery storage system in PA? – or all three – leave the heavy-lifting to us, so you can focus on running and managing your portfolio."
          }
        />
      </Helmet>
      <FullPageOrangeSection>
        <div
          css={css`
            width: 100%;
          `}
        >
          <Hero
            fluid={data.contentfulHeroImage.heroImage.fluid}
            alt={data.contentfulHeroImage.alt}
            type={"WtoO"}
          />
          <br />
          <TitleText color="green">CUSTOM SOLUTIONS</TitleText>
        </div>
        <br />
        <BlurbWrapper>
          <BlurbText color="#1a4428" margin={true}>
            Whether your target market is C&amp;I, DG or Utility Scale BOW’s
            services and expertise cover the entire scope of the solar industry.
            Need a construction manager in Texas? A project manager Colorado? A
            battery storage system in PA? – or all three – leave the
            heavy-lifting to us, so you can focus on running and managing your
            portfolio.
          </BlurbText>
        </BlurbWrapper>
        <br />
      </FullPageOrangeSection>
      <FullPageWhiteSection>
        <div
          css={css`
            width: 100%;
          `}
        >
          <HexTransition type={"OtoW"} />
        </div>
        <br />
        <br />
        <PartnershipHeaderImages />
        <br />
        <br />
        <BlurbWrapper>
          <BlurbText color="#1a4428" margin={true}>
            No other energy consulting firm in the market can offer what BOW
            Renewables provides. Our unique, targeted approach to your renewable
            energy project is backed by over 30 years of combined experience.
            Your tailored consulting package is based on your individual needs,
            and we address every step of the process. From Greenfield
            development and design to permitting and construction, BOW
            Renewables is your source for an expertly planned Commercial
            Operation Date (COD).{" "}
            <BlurbLink to="/about#contact" color="#215732" stripHash>
              Contact us
            </BlurbLink>{" "}
            today and get ahead of your competition with confidence.
          </BlurbText>
        </BlurbWrapper>
        <br />
      </FullPageWhiteSection>
    </MainLayout>
  );
};

export default CustomSolutions;

export const query = graphql`
  query PartnerQuery {
    contentfulHeroImage(slug: { eq: "partnershipHero" }) {
      heroImage {
        fluid(maxWidth: 3080, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      heroAlt
    }
  }
`;
