import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import { DescriptionWrapper } from "./styled-components";
import ImageWrapper from "./image-wrapper";

const ContentBlock = styled("div")`
  width: 25vw;
  height: fit-content;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1024px) {
    width: 75vw;
    margin: 20px auto;
  }
  @media (max-width: 960px) {
    width: 90vw;
    margin: 20px auto;
  }
`;

const PartnershipHeaderImages = () => {
  const partnerImages = useStaticQuery(graphql`
    query {
      allContentfulImage(filter: { tags: { in: "partnership" } }) {
        nodes {
          alt
          slug
          image {
            fluid(quality: 90, maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `);
  const startImage = partnerImages.allContentfulImage.nodes.find(
    (image) => image.slug === "partnershipOne"
  );
  const middleImage = partnerImages.allContentfulImage.nodes.find(
    (image) => image.slug === "partnershipTwo"
  );
  const endImage = partnerImages.allContentfulImage.nodes.find(
    (image) => image.slug === "partnershipThree"
  );

  return (
    <div
      css={css`
        width: 95%;
        display: flex;
        justify-content: space-around;
        @media (max-width: 1024px) {
          flex-direction: column;
          align-items: center;
        }
      `}
    >
      <ContentBlock>
        <ImageWrapper fluid={startImage.image.fluid} alt={startImage.alt} />
        <br />
        <DescriptionWrapper>
          <p>Setting sights on the target.</p>
        </DescriptionWrapper>
      </ContentBlock>
      <ContentBlock>
        <ImageWrapper fluid={middleImage.image.fluid} alt={middleImage.alt} />
        <br />
        <DescriptionWrapper>
          <p>Target in sight.</p>
        </DescriptionWrapper>
      </ContentBlock>
      <ContentBlock>
        <ImageWrapper fluid={endImage.image.fluid} alt={endImage.alt} />
        <br />
        <DescriptionWrapper>
          <p>Right on target.</p>
        </DescriptionWrapper>
      </ContentBlock>
    </div>
  );
};

export default PartnershipHeaderImages;
